@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

body {
  overflow-y: scroll;
  height: 100%;
  position: fixed;
  font-family: "Nunito", sans-serif;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100vw;
  width: 100vw;
  background-color: #202323;
}
